//RESPONSIVE BREAKPOINTS
$bp-largest: 75em; //1200px;
$bp-large: 62.5em; //1000px;
$bp-medium-l: 56.25em; //900px;
$bp-medium: 50em; //800px;
$bp-medium-s: 43.75em; //700px;
$bp-small: 37.5em; //600px;
$bp-smallest: 31.25em; //500px;

$fontstyle-main: "Quicksand", sans-serif;
$fontstyle-sub: "Varela Round", sans-serif;

$color-main: #5ea8f5;
$color-main-dark: #2ebef2;
$color-secondary: #f5ab5e;
$color-secondary-2: #5ef3f5;
$color-secondary-3: #605ef5;
$color-tertiary: #f55ea8;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  box-sizing: inherit;
  font-size: 62.5%;

  @media screen and (max-width: $bp-largest) {
    //font-size: 50%;
  }
}

.App {
  display: grid;
  grid-template-rows: min-content, min-content, min-content;
  grid-template-columns: 1fr repeat(8, minmax(min-content, 14rem)) 1fr;
  background-color: #f2f2f2;
}

.Container {
  grid-row: 2 / span 1;
  grid-column: 2 / 10;
  height: calc(100vh - 12rem);

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $bp-medium-s) {
    flex-direction: column;
  }
}

.Nav {
  grid-row: 1 / span 1;
  grid-column: 2 / 10;
  height: 8rem;
  font-family: $fontstyle-main;
  font-weight: 500;
  overflow: hidden;

  &__content {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__icon {
    height: 100%;
    width: 10rem;
    //justify-self: start;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    cursor: pointer;
    color: $color-main-dark;
    background-color: #f2f2f2;
    z-index: 10;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    @media screen and (max-width: $bp-small) {
      width: 8rem;
      font-size: 2.5rem;
    }

    @media screen and (max-width: $bp-smallest) {
      width: 6rem;
      font-size: 2rem;
    }

    & span {
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      color: $color-secondary;
    }
  }

  &__list {
    list-style-type: none;
    position: absolute;
    right: 10rem;
    top: 50%;
    transition: all 0.6s;

    z-index: 8;
    display: flex;

    @media screen and (max-width: $bp-small) {
      right: 8rem;
    }

    @media screen and (max-width: $bp-small) {
      right: 6rem;
    }

    &--item {
      margin-right: 4rem;

      @media screen and (max-width: $bp-small) {
        margin-right: 1rem;
      }

      &-link {
        font-size: 1.6rem;
        text-transform: uppercase;
        line-height: 1.6;
        letter-spacing: 0.3rem;
        text-decoration: none;
        color: $color-main;

        @media screen and (max-width: $bp-small) {
          font-size: 1.4rem;
        }

        @media screen and (max-width: $bp-smallest) {
          font-size: 1.2rem;
          letter-spacing: 0.1rem;
        }

        &:hover {
          color: $color-main-dark;
        }
      }

      &-active {
        color: $color-secondary;
      }
    }
  }
}

.heading {
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 3rem;
  margin-top: 2rem;
  text-align: center;
  color: $color-main;

  @media screen and (max-width: $bp-small) {
    font-size: 2rem;
  }

  @media screen and (max-width: $bp-small) {
    font-size: 1.8rem;
  }
}

.heading-sub {
  line-height: 1.6;
  letter-spacing: 0.2rem;
  font-size: 1.8rem;
  text-align: left;
  margin-bottom: 2rem;
  color: $color-main;

  @media screen and (max-width: $bp-small) {
    font-size: 1.4rem;
  }

  @media screen and (max-width: $bp-small) {
    font-size: 1.2rem;
  }
}

.paragraph {
  letter-spacing: 0.2rem;
  line-height: 1.6;
  font-size: 1.6rem;
  color: #4c4c4c;

  @media screen and (max-width: $bp-small) {
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
  }

  @media screen and (max-width: $bp-small) {
    font-size: 1.2rem;
  }
}

.list {
  list-style-type: none;
  line-height: 1.6;
  letter-spacing: 0.2rem;
  margin-bottom: 2rem;

  @media screen and (max-width: $bp-small) {
    letter-spacing: 0.1rem;
    margin-bottom: 1.5rem;
  }

  &:last-child {
    padding-bottom: 4rem;
  }

  &__item {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: #4c4c4c;

    @media screen and (max-width: $bp-small) {
      font-size: 1.4rem;
    }

    @media screen and (max-width: $bp-small) {
      font-size: 1.2rem;
    }

    &-icon {
      margin-right: 4rem;
    }

    &-skill {
    }
  }
}

.Info {
  height: 100%;
  width: 30%;
  padding: 0 3rem;
  //background-color: lightgreen;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $bp-medium-s) {
    //flex-direction: column;
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: $bp-small) {
    padding: 0 1rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $bp-medium-s) {
      flex-direction: row;
    }

    @media screen and (max-width: $bp-small) {
      width: 100%;
    }

    @media screen and (max-width: $bp-smallest) {
      justify-content: space-around;
      flex-direction: column;
    }

    &--img {
      height: 15rem;
      width: 15rem;
      margin-bottom: 6rem;
      position: relative;

      @media screen and (max-width: $bp-medium-s) {
        margin: 3rem;
      }

      @media screen and (max-width: $bp-small) {
        height: 10rem;
        width: 10rem;
        margin: 1rem;
        margin-bottom: 4rem;
      }

      &::before {
        content: "";
        height: 16.5rem;
        width: 16.5rem;
        display: inline-block;
        position: absolute;
        top: -0.75rem;
        left: -0.75rem;
        background-color: transparent;
        border-radius: 50%;
        border: 0.2rem solid $color-secondary-3;

        @media screen and (max-width: $bp-small) {
          height: 11.5rem;
          width: 11.5rem;
        }
      }

      &::after {
        content: "";
        height: 18rem;
        width: 18rem;
        display: inline-block;
        position: absolute;
        top: -1.5rem;
        left: -1.5rem;
        background-color: transparent;
        border-radius: 50%;
        border: 0.4rem solid $color-secondary-2;
        transition: all 0.6s;

        @media screen and (max-width: $bp-small) {
          height: 13rem;
          width: 13rem;
        }
      }

      &:hover::after {
        border: 0.4rem solid $color-main;
      }

      &:hover &-1 {
        border: 0.4rem solid $color-main;
        transform: scale(1.2);
      }

      &-1 {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        overflow: hidden;
        transition: all 0.6s;
        border: 0.1rem solid $color-main-dark;
      }
    }

    &--text {
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      line-height: 1.6rem;
      text-align: center;
      color: $color-main-dark;

      @media screen and (max-width: $bp-medium-s) {
        margin-left: 2rem;
      }

      @media screen and (max-width: $bp-smallest) {
        margin-left: 0.5rem;
      }

      &-heading-1 {
        font-size: 1.8rem;
        margin-bottom: 2rem;
        font-family: $fontstyle-main;
        font-weight: 500;

        @media screen and (max-width: $bp-small) {
          font-size: 1.6rem;
        }
      }

      &-heading-2 {
        font-family: $fontstyle-sub;
        font-weight: 400;
        font-size: 1.2rem;
        color: #4c4c4c;

        @media screen and (max-width: $bp-small) {
          font-size: 1rem;
        }
      }
    }
  }
}

.Pages {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  padding-right: 2rem;
  box-sizing: content-box;
  overflow-x: hidden;
  overflow-y: auto;
}

.Scrollable {
  height: 100%;
  width: 70%;
  //padding-right: 2rem;
  box-sizing: content-box;

  overflow: hidden;
  //overflow-y: auto;
  overflow-x: hidden;
}

.Footer {
  grid-row: 3 / span 1;
  grid-column: 2 / 10;
  font-family: $fontstyle-main;
  font-weight: 400;
  height: 4rem;
  width: 100%;
  color: $color-main-dark;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.2rem;

    &--copyright {
      font-size: 1.4rem;
    }
  }
}

.About,
.Skills,
.Project,
.Contact {
  font-family: $fontstyle-sub;
  text-align: center;
  padding: 3rem;
  width: 100%;
  height: 100%;

  @media screen and (max-width: $bp-small) {
    padding: 0;
  }
}

.About {
  max-width: $bp-medium;
  margin: 0 auto;
  text-align: justify;

  & p {
    margin-bottom: 15px;
  }
}

.Skills,
.Contact,
.Project {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Skills {
  align-items: start;
}

.Project {
  &__item {
    display: grid;
    grid-template-rows: auto-fit;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    row-gap: 2rem;
    column-gap: 2rem;
    padding: 1rem;
    margin: 1rem;
    margin-right: 2.5rem;

    @media screen and (max-width: $bp-smallest) {
      grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
      padding: 0.5rem;
      margin: 0.5rem;
      margin-right: 1.5rem;
    }

    &:last-child {
      padding-bottom: 10rem;
      margin-bottom: 10rem;
    }

    &--content {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      border: 2px solid rgba(216, 216, 216, 0.5);
      border-radius: 0.5rem;
      letter-spacing: 0.2rem;
      box-shadow: 1px 2px 1rem rgba(0, 0, 0, 0.2);

      &:hover {
        box-shadow: 1px 1px 1.5rem rgba(0, 0, 0, 0.3);
      }

      &-h1 {
        font-size: 2.5rem;
        text-transform: capitalize;
        margin-bottom: 1rem;
        color: $color-main;

        @media screen and (max-width: $bp-small) {
          font-size: 2rem;
        }

        @media screen and (max-width: $bp-smallest) {
          font-size: 1.6rem;
        }
      }

      &-p {
        align-self: flex-start;
        font-size: 1.6rem;
        margin-bottom: 2rem;
        color: #4c4c4c;

        @media screen and (max-width: $bp-small) {
          font-size: 1.4rem;
          margin-bottom: 1.5rem;
        }

        @media screen and (max-width: $bp-smallest) {
          font-size: 1.2rem;
          margin-bottom: 1rem;
        }
      }

      &-a {
        align-self: flex-end;
        text-decoration: none;
        text-transform: uppercase;
        padding: 1rem 2rem;
        background-color: $color-main-dark;
        border-radius: 10rem;
        color: #f7f7f7;
        font-weight: 600;
        margin-bottom: 1rem;

        @media screen and (max-width: $bp-smallest) {
          margin-bottom: 0.5rem;
        }

        &:hover {
          background-color: $color-secondary;
        }
      }
    }
  }
}

.example-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.example-enter.example-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 500ms ease-in;
}

.example-leave {
  opacity: 1;
  transform: translateX(0);
}

.example-leave.example-leave-active {
  opacity: 0;
  transform: translateX(100%);
  transition: all 300ms ease-in;
}
